<template>
  <v-app class="background">
    <v-app-bar app light>
      <v-app-bar-nav-icon
        v-if="false && $vuetify.breakpoint.smAndDown"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img
            v-if="$vuetify.breakpoint.smAndUp"
            contain
            height="60"
            width="300"
            :src="require('@/assets/Anasayfa_Logo.png')"
          />
          <v-img
            v-if="$vuetify.breakpoint.xsOnly"
            contain
            height="50"
            width="250"
            :src="require('@/assets/Anasayfa_Logo.png')"
          />
        </router-link>
      </div>
      <v-spacer />
      <v-tabs centered v-if="$vuetify.breakpoint.mdAndUp">
        <v-tab v-for="item in menu" :to="item.to" :key="item.to">
          {{ item.title }}
        </v-tab>
      </v-tabs>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" temporary>
      <v-list nav dense>
        <template v-for="item in menu">
          <v-list-item
            v-if="!item.children || $vuetify.breakpoint.mdAndUp"
            :to="item.to"
            :key="item.to"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-group v-else :key="item.to" :value="true" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.children"
              :exact="child.exact"
              :key="child.to"
              :to="child.to"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
    <v-dialog :value="streamPopup" width="500">
      <v-card>
        <v-card-text class="pt-5 text-h5">
          Yayınımızın soru cevap kısmı başlamak üzeredir.
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            to="/stage/main"
            class="ma-2"
            @click="streamPopup = false"
          >
            Ana Sahne
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  name: 'DefaultLayout',
  components: {},
  data() {
    return {
      drawer: false,
      streamPopup: false,
      menu: []
    };
  },
  /*
  sockets: {
    stream_popup() {
      if (this.$route.name !== 'stage-stage') {
        this.streamPopup = true;
      }
    }
  },
  */
  created() {},
  beforeDestroy() {},
  watch: {},
  methods: {}
};
</script>

<style scoped>
.background {
  background: url('../assets/Arcelik_Anasayfa_BG.png') no-repeat center center !important;
  background-size: cover !important;
}
</style>
